// Setup
window.sendFileListEmails = [];

// Logic to show tooltip by clicking info button
function showCarbonCopyTooltipI() {
  const tooltip = document.getElementById('carbon-copy-tooltip');
  const modalContainer = document.getElementById('send-emails-modal').firstElementChild;
  const tooltipIcon = document.getElementById('carbon-copy-tooltip-icon');
  const displacement = tooltipIcon.getBoundingClientRect().left - modalContainer.offsetLeft;
  tooltip.classList.remove('tw-hidden');
  if (window.innerWidth < 576) {
    tooltip.style.left = `${displacement - tooltip.offsetWidth / 2 - 26}px`;
  } else {
    tooltip.style.left = `${displacement - 40}px`;
  }
}

window.showCarbonCopyTooltipI = showCarbonCopyTooltipI;

// design for each email label in the list
function sendFileEmailsDesign(email) {
  return `<div class="tw-info-green tw-px-2 tw-gap-1 tw-flex tw-rounded tw-items-center tw-py-0.5">
    <p class="tw-m-0 tw-text-sm">${email}</p>
    <button onclick="removeEmailSendFile(this)">
    <svg class="tw-w-4 tw-h-4 tw-text-green-800 heroicon-exit" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.5"></path>
    </svg>
    </button>
    </div>`;
}

// Render email's label that are been added to the list
function sendFileRenderEmails(emails) {
  const emailsContainer = document.getElementById('emails_list-container');
  const temp = document.createElement('template');

  emails.forEach((email) => {
    temp.innerHTML = sendFileEmailsDesign(email);
    emailsContainer.append(temp.content.firstChild);
  });
}
window.sendFileRenderEmails = sendFileRenderEmails;

// Validate emails in input
function sendFileValidateEmails() {
  const emailsInput = document.getElementById('send-emails_input');

  const button = document.getElementById('send-add_emails');
  const arrayOfEmails = emailsInput.value.split(',');
  if (emailsInput.value !== '') {
    const allValid = arrayOfEmails.reduce((isValid, email) => isValid && (email.trim() === '' || window.isValidEmail(email.trim())), true);
    if (allValid) {
      button.classList.add('tw-btn-action-blue');
      button.classList.remove('tw-btn-disabled');
      emailsInput.classList.remove('tw-border-red-500');
      emailsInput.classList.remove('tw-text-red-500');
      document.querySelector('.email_error').classList.add('tw-hidden');
    } else {
      button.classList.add('tw-btn-disabled');
      button.classList.remove('tw-btn-action-blue');
      emailsInput.classList.add('tw-border-red-500');
      emailsInput.classList.add('tw-text-red-500');
      document.querySelector('.email_error').classList.remove('tw-hidden');
    }
  } else {
    emailsInput.classList.remove('tw-border-red-500');
    document.querySelector('.email_error').classList.add('tw-hidden');
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-btn-action-blue');
  }
}

// Define listener for emails input
function addListenerToEmailsInputI() {
  const emailsInput = document.getElementById('send-emails_input');
  emailsInput.addEventListener('input', window.debounce(() => {
    sendFileValidateEmails();
  }, 1000));

  emailsInput.addEventListener('change', () => {
    sendFileValidateEmails();
  });
}

// Define listeners for tooltip information label
function addListenersToHideCarbonCopyTooltipI() {
  const tooltip = document.getElementById('carbon-copy-tooltip');
  document.addEventListener('mouseup', (event) => {
    if (!tooltip.contains(event.target)) {
      tooltip.classList.add('tw-hidden');
    }
  });
  document.querySelector('#send-emails-modal .tw-overflow-x-auto').addEventListener('scroll', () => {
    tooltip.classList.add('tw-hidden');
  });
}

// Add event listeners to document
document.addEventListener('DOMContentLoaded', () => {
  const body = document.querySelector('#proposals-index, #inspections_proposals-index, #inspection');
  if (body) {
    if (body.id === 'proposals-index' || body.id === 'inspections_proposals-index') {
      addListenersToHideCarbonCopyTooltipI();
    }
    addListenerToEmailsInputI();
  }
});

// Validate emails list to send inspection
function validateEnableSendFile() {
  const currentModal = document.getElementById('send-emails-modal');
  const dropdownFileType = currentModal.querySelector('#dropdown-file-type');
  let fileTypeCheck = true;
  if (dropdownFileType && !dropdownFileType.querySelector('.li-check')) {
    fileTypeCheck = false;
  }
  const button = document.querySelector('.submit');
  if (window.sendFileListEmails.length > 0 && fileTypeCheck) {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-action-blue');
  } else {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-btn-action-blue');
  }
}

window.validateEnableSendFile = validateEnableSendFile;

// Add emails to email list
function addEmailsToSendFile() {
  const emailsInput = document.getElementById('send-emails_input');
  let arrayOfEmails = emailsInput.value.split(',').map((email) => email.trim().toLowerCase());
  arrayOfEmails = arrayOfEmails.filter((email) => !window.sendFileListEmails.includes(email.toLowerCase()) && email !== '');
  arrayOfEmails = [...new Set(arrayOfEmails)];
  sendFileRenderEmails(arrayOfEmails);
  window.sendFileListEmails = window.sendFileListEmails.concat(arrayOfEmails);
  emailsInput.value = '';
  emailsInput.classList.remove('tw-hidden');
  document.getElementById('send-add_emails').classList.add('tw-btn-disabled');
  document.getElementById('send-add_emails').classList.remove('tw-btn-action-blue');
  validateEnableSendFile();
}

window.addEmailsToSendFile = addEmailsToSendFile;

function addEmailToSendFile(email) {
  if (!window.sendFileListEmails.includes(email.toLowerCase())) {
    sendFileRenderEmails([email]);
    window.sendFileListEmails.push(email.toLowerCase());
    validateEnableSendFile();
  }
}
window.addEmailToSendFile = addEmailToSendFile;

// Remove email from email list
function removeEmailSendFile(element) {
  const email = element.previousElementSibling.innerText;
  window.sendFileListEmails = window.sendFileListEmails.filter((emailAdded) => emailAdded !== email);
  element.parentElement.remove();
  validateEnableSendFile();
}
window.removeEmailSendFile = removeEmailSendFile;

// Reset modal when closing it
function cleanModal() {
  const currentModal = document.getElementById('send-emails-modal');
  const dropdownElement = currentModal.querySelector('.dropdown-content');
  if (dropdownElement) {
    const checkedOption = currentModal.getElementsByClassName('li-check')[0];
    if (checkedOption) {
      checkedOption.classList.remove('li-check');
      checkedOption.classList.add('li-no-check');
      checkedOption.firstElementChild.classList.add('tw-hidden');
    }
    dropdownElement.textContent = window.I18n.select;
  }
  document.getElementById('emails_list-container').innerHTML = '';
  document.getElementById('send-emails_input').value = '';
  window.sendFileListEmails = [];
  sendFileValidateEmails();
  validateEnableSendFile();
}

window.cleanModal = cleanModal;

function updateTableWithData(response) {
  if (document.querySelector('#proposals-index, #inspections_proposals-index')) {
    const dataTable = $('#dataTable'); // eslint-disable-line no-undef

    if (dataTable.length > 0) {
      dataTable.DataTable().ajax.reload(null, false);
    } else {
      const proposal = response.result.inspections_proposal;
      document.querySelector(`#proposal-${proposal.id} .last-sent-attr`).innerHTML = proposal.custom_formats.last_sent_at;
      document.querySelector(`#proposal-${proposal.id} .status-attr`).innerHTML = window.I18n.proposal.status[`n${proposal.custom_formats.raw_status}`];
    }
  }
}

function defineUrlToSendFiles(resource) {
  const id = document.getElementById('send-emails-modal').dataset.resourceId;
  let url = null;
  switch (resource) {
    case 'proposal':
      url = `/inspections_proposals/${id}/send_email`;
      break;
    case 'inspection':
      url = `/inspections/${id}/send_email`;
      break;
    default:
      url = 'not implemented send email for resource';
      break;
  }
  return url;
}

function defineDataToSendFiles(resource) {
  const data = { emails: window.sendFileListEmails };
  if (resource === 'inspection') {
    const currentModal = document.getElementById('send-emails-modal');
    const fileType = currentModal.querySelector('.li-check');
    if (fileType) {
      data.file_type = fileType.dataset.id;
    }
  }
  return JSON.stringify(data);
}

// Send emails
function sendFileEmail() {
  const resource = document.body.dataset.resource;
  document.querySelector('.submit').classList.remove('tw-btn-action-blue');
  document.querySelector('.submit').classList.add('tw-btn-disabled');
  window.$.ajax({
    url: defineUrlToSendFiles(resource),
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    type: 'POST',
    data: defineDataToSendFiles(resource),
    success: (response) => {
      window.closeInformationModal('send-emails-modal');
      window.toggleSnackbar('success-snackbar', window.I18n.shared.send_file_email_modal[resource].success_message);
      cleanModal();
      updateTableWithData(response);
    },
    error: () => {
      window.toggleSnackbar('warning-snackbar', window.I18n.shared.send_file_email_modal.error_message);
    },
  });
}
window.sendFileEmail = sendFileEmail;

// Load suggested emails for table
function showSendEmailsModal(element = null) {
  let siteId = null;
  let resourceId = null;
  if (element) {
    siteId = element.getAttribute('site_id');
    resourceId = element.getAttribute('id');
  } else {
    const resourceType = document.body.dataset.resource;
    const resourceElement = document.getElementById(resourceType);
    siteId = resourceElement.dataset.siteId;
    resourceId = resourceElement.dataset.inspectionId;
  }
  document.getElementById('send-emails-modal').dataset.resourceId = resourceId;
  document.getElementById('send-emails-modal').dataset.siteId = siteId;
  window.$.ajax({
    url: `/sites/${siteId}/suggested_emails`,
    dataType: 'script',
    type: 'GET',
  });
}

window.showSendEmailsModal = showSendEmailsModal;
