function getConnectivityHealthChart() {
  const loader = document.querySelector('#connectivity-health-body #loading');
  document.getElementById('connectivity-health-content').classList.add('tw-hidden');
  document.querySelector('#connectivity-health-body #empty').classList.add('tw-hidden');
  loader.classList.remove('tw-hidden');
  window.$.ajax({
    url: '/insights/connectivity_health_metrics/controllers_health',
    method: 'GET',
    data: { branch_id: document.querySelector('#select_branch #branch_id').value },
    success: () => {
      loader.classList.add('tw-hidden');
    },
  });
}
window.getConnectivityHealthChart = getConnectivityHealthChart;
